import React from 'react';
import './style_presentation_concrete_pool.css';
//import { AiOutlinePlus } from 'react-icons/ai';

function PresentationConcretePool() {
  return (
    <div
      id="concrete_pool_anchor"
      className="presentation_concrete_pool_conteiner"
    >
      <div className="presentation_concrete_pool">
        {/* <div className="bread_crumbs">
          <p>Главная</p>
          <p>/</p>
          <p>Бетонные бассейны</p>
        </div> */}
        <h1>
          БЕТОННЫЕ
          <br /> бассейны
        </h1>
        <div className="presentation_concrete_pool_text">
          <p>
            Бетонные бассейны являются одним из самых популярных видов бассейнов
            благодаря своей прочности, долговечности и доступности. Они могут
            быть различных форм и размеров, а также могут быть украшены
            различными элементами декора.
          </p>
          <p>
            Однако, бетонные бассейны требуют больше времени на установку и
            могут быть более дорогими в обслуживании, чем другие типы бассейнов.
          </p>
        </div>
        {/* <div className="more_details_button">
          <button>
            <AiOutlinePlus color="white" />
          </button>
          <p>Подробнее</p>
        </div> */}
      </div>
    </div>
  );
}

export default PresentationConcretePool;
