import React from 'react';
import { useState, useEffect } from 'react';
import './style_design_stages_slider.css';
import data_sauna from './data_sauna/data_sauna';
import data_hammam from './data_hammam/data_hammam';
import { MdArrowBackIosNew } from 'react-icons/md';
import { MdArrowForwardIos } from 'react-icons/md';
import img_sauna from './data_sauna/img/099a18c60e00fcf99045599591af0ed7.png';
import img_hammam from './data_hammam/img/3076f9dad907b92c9cf5761540cffec5.png';

function DesignStagesSlider({ object }) {
  function checkObject(object) {
    if (object === 'hammam') {
      return data_hammam;
    } else {
      return data_sauna;
    }
  }

  // console.log(gen_data.slice(0, 4));

  // function checkViewport() {
  //   if (window.visualViewport.width < 760) {
  //     return checkObject(object).slice(0, 4);
  //    } else {
  //     return checkObject(object)
  //    }
  // }

  const gen_data = checkObject(object);

  const [translate, setTranslate] = useState(0);

  function HandleNextScroll() {
    setTranslate(translate + 640);
  }

  function HandlePreviosScroll() {
    setTranslate(translate - 640);
  }

  useEffect(() => {
    if (translate === 0) {
      document.querySelector('.past_scroll_design_stages').disabled = true;
      document.querySelector('.next_scroll_design_stages').disabled = false;
    } else if (translate < 0 && translate > -640 * (gen_data.length - 2)) {
      document.querySelector('.past_scroll_design_stages').disabled = false;
      document.querySelector('.next_scroll_design_stages').disabled = false;
    } else if (translate <= -640 * (gen_data.length - 2)) {
      document.querySelector('.past_scroll_design_stages').disabled = false;
      document.querySelector('.next_scroll_design_stages').disabled = true;
    }

    document.querySelector(
      '.stages_slider'
    ).style.transform = `translateX(${translate}rem)`;
  }, [translate, gen_data]);

  return (
    <div className="design_stages_slider_conteiner">
      <div
        className="design_stages_slider_img"
        style={
          object === 'hammam'
            ? { backgroundImage: `url(${img_hammam})` }
            : { backgroundImage: `url(${img_sauna})` }
        }
      ></div>
      <div className="design_stages_slider_paragraph">
        <h2>Этапы</h2>
        <h2>Проектирования</h2>
      </div>
      <div className="design_stages_slider_buttons">
        <div className="stages_slider">
          {window.visualViewport.width > 760
            ? gen_data.map((data_obj) => {
                return (
                  <div
                    key={data_obj.id}
                    className="design_stages_card_conteiner"
                  >
                    <div className="design_stages_data_text">
                      <h1>{'0' + data_obj.id}</h1>
                      <h2>{data_obj.paragraph_design_stages}</h2>
                      <p>{data_obj.text_design_stages}</p>
                    </div>
                  </div>
                );
              })
            : gen_data.slice(0, 4).map((data_obj) => {
                return (
                  <div
                    key={data_obj.id}
                    className="design_stages_card_conteiner"
                  >
                    <div className="design_stages_data_text">
                      <h1>{'0' + data_obj.id}</h1>
                      <h2>{data_obj.paragraph_design_stages}</h2>
                      <p>{data_obj.text_design_stages}</p>
                    </div>
                  </div>
                );
              })}
        </div>
        <div className="design_stages_buttons">
          <button
            className="past_scroll_design_stages buttons_design_stages"
            onClick={HandleNextScroll}
          >
            <MdArrowBackIosNew />
          </button>
          <button
            className="next_scroll_design_stages buttons_design_stages"
            onClick={HandlePreviosScroll}
          >
            <MdArrowForwardIos />
          </button>
        </div>
      </div>
    </div>
  );
}

export default DesignStagesSlider;
