import React from 'react';
import './style_presentation_hammam.css';

function PresentationHammam() {
  return (
    <div className="presentation_hammam_conteiner">
      {/* <div className="bread_crumbs">
        <p>Главная</p>
        <p>/</p>
        <p>Хаммам</p>
      </div> */}
      <div className="presentation_hammam_text">
        <h1>Хаммам</h1>
        <h2>от проекта до реализации</h2>
        <p>
          Здесь мы предлагаем вам ознакомиться с информацией о различных
          аспектах строительства хаммамов, а также подобрать оптимальные решения
          для вашего проекта.
        </p>
      </div>
      <div className="presentation_hammam_img">
        <div className="presentation_hammam_img_1 hammam_img"></div>
        <div className="presentation_hammam_img_2 hammam_img"></div>
        <div className="presentation_hammam_img_3 hammam_img"></div>
      </div>
    </div>
  );
}

export default PresentationHammam;
