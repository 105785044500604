const data_concrete_pool = [
  {
    id: 1,
    img: require('./img_concrete_pool/0FF1D0AD-04B6-4F17-858B-BBBCAF447CE1.png'),
  },
  {
    id: 2,
    img: require('./img_concrete_pool/21FEF2B1-1FC3-40F2-AB49-5A0BA82938F9.png'),
  },
  {
    id: 3,
    img: require('./img_concrete_pool/32537234-19FC-44F7-A396-47F0D44714E1.png'),
  },
  {
    id: 4,
    img: require('./img_concrete_pool/680B321A-AF87-447D-8B2A-4AF96C6D4F6A.png'),
  },
  {
    id: 5,
    img: require('./img_concrete_pool/7466F29B-49F5-454C-99E1-3E2BA3E91631.png'),
  },
  {
    id: 6,
    img: require('./img_concrete_pool/8D3CFAF4-47D5-4033-AC64-440B4B621B45.png'),
  },
  {
    id: 7,
    img: require('./img_concrete_pool/CE737080-B63C-4C48-96AE-BCFE920B492A.png'),
  },
  {
    id: 8,
    img: require('./img_concrete_pool/IMG_6842.png'),
  },
  {
    id: 9,
    img: require('./img_concrete_pool/IMG_6853.png'),
  },
  {
    id: 10,
    img: require('./img_concrete_pool/IMG_6856.png'),
  },
  {
    id: 11,
    img: require('./img_concrete_pool/IMG_6858.png'),
  },
  {
    id: 12,
    img: require('./img_concrete_pool/IMG_6860.png'),
  },
];

export default data_concrete_pool;
