const data_composite_pool = [
  {
    id: 1,
    img: require('./img_composite_pool/DJI_0232-2.png'),
  },
  {
    id: 2,
    img: require('./img_composite_pool/IMG_9843.png'),
  },
  {
    id: 3,
    img: require('./img_composite_pool/IMG_9845.png'),
  },
  {
    id: 4,
    img: require('./img_composite_pool/IMG_9848.png'),
  },
  {
    id: 5,
    img: require('./img_composite_pool/IMG_9854.png'),
  },
  {
    id: 6,
    img: require('./img_composite_pool/IMG_9859.png'),
  },
  {
    id: 7,
    img: require('./img_composite_pool/IMG_9863.png'),
  },
  {
    id: 8,
    img: require('./img_composite_pool/IMG_9864.png'),
  },
  {
    id: 9,
    img: require('./img_composite_pool/___2021__22-23_00000.png'),
  },
];

export default data_composite_pool;
