import React from 'react';
import './style_spa_zone.css';
import PresentationSpaZone from './block_1_presentation_spa_zone/PresentationSpaZone';
import Header from '../../modules/Header/Header';
import Footer from '../../modules/Footer/Footer';
import ChooseSpaZone from './block_2_choose_spa_zone/ChooseSpaZone';
import TransitionPages from '../../modules/Transition_Pages/TransitionPages';

function SpaZone() {
  return (
    <div className="spa_zone_conteiner">
      <Header />
      <PresentationSpaZone />
      <ChooseSpaZone />
      <Footer />
    </div>
  );
}

export default TransitionPages(SpaZone);
