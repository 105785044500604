const data_hammam = [
  {
    id: '1',
    paragraph_design_stages: 'Определение целей и задач',
    text_design_stages:
      'Необходимо определиться с тем, какой хаммам вы хотите создать - традиционный турецкий или современный, с элементами спа. А также, учесть его размеры, вместимость и наличие дополнительных услуг.',
  },
  {
    id: '2',
    paragraph_design_stages: 'Выбор места',
    text_design_stages:
      'Важно выбрать подходящее место для хаммама. Оно должно быть уютным, проветриваемым и обеспечивать достаточное пространство для комфортного пребывания. Также следует учесть близость к водопроводу и канализации.',
  },
  {
    id: '3',
    paragraph_design_stages: 'Создание проекта',
    text_design_stages:
      'На основе выбранных параметров создается проект хаммама. Он включает в себя план помещения, чертежи стен, потолка, пола, а также схему электропроводки, водоснабжения и системы пара.',
  },
  {
    id: '4',
    paragraph_design_stages: 'Выбор материалов',
    text_design_stages:
      'После создания проекта можно приступать к выбору материалов для хаммама. Важно использовать термостойкие и прочные материалы, способные выдерживать высокую влажность и температуру.',
  },
  {
    id: '5',
    paragraph_design_stages: 'Строительство',
    text_design_stages:
      'На данном этапе происходит непосредственно строительство хаммама. Сначала устанавливаются основа и стены, затем монтируется потолок и пол, проводится электропроводка и система подачи пара, устанавливаются двери и вентиляционные отверстия.',
  },
  {
    id: '6',
    paragraph_design_stages: 'Отделка',
    text_design_stages:
      'Последним этапом является отделка хаммама. Стены и потолок могут быть выложены кафелем или мозаикой, пол также можно покрыть кафельной плиткой или специальным прочным материалом. Необходимо также установить мебель и аксессуары, специальные кресла и шезлонги для отдыха.',
  },
];

export default data_hammam;
