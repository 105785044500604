import React from 'react';
import './style_presentation_about_company.css';
import img_wave from './img/WAWE.png';

function PresentationAboutCompany() {
  return (
    <div className="presentation_about_company_conteiner">
      {/* <div className="bread_crumbs">
        <p>Главная</p>
        <p>/</p>
        <p>Сауна</p>
      </div> */}
      <div className="presentation_about_company">
        <h1>
          <span>Gran Gota</span> - Ваш
          <br /> надежный партнер в строительстве бассейнов
        </h1>
        <p>
          Наша компания специализируется на проектировании, строительстве и обслуживании
          бассейнов, саун, хаммамов и Спа-зон любых типов и размеров.
        </p>
        <div className="presentation_about_company_years_text">
          <h2>14 лет</h2>
          <h3>
            на рынке и за это время мы успели заслужить доверие многих клиентов
            и партнеров.
          </h3>
        </div>
        <div className="presentation_about_company_img"></div>
        <img
          className="presentation_about_company_img_wave"
          src={img_wave}
          alt="graphic_wave"
        />
      </div>
    </div>
  );
}

export default PresentationAboutCompany;
