import React from 'react';
import './style_why_choose_us.css';
import PopUpForm from '../../../modules/PopUpForm/PopUpForm';

function WhyChooseUs() {
  return (
    <div className="why_choose_us_conteiner">
      <div className="why_choose_us">
        <div className="advantages_text_conteiner">
          <h3>преимущества</h3>
          <h2>
            Почему нас выбирают более <span>50% населения?</span>
          </h2>
        </div>
        <div className="c1 why_chose_cards">
          <div className="why_chose_text_conteiner">
            <div className="paragraph_svg">
              <svg
                width="54rem"
                height="54rem"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="vuesax/bulk/timer">
                  <g id="timer">
                    <path
                      id="Vector"
                      opacity="0.4"
                      d="M41.2873 44.28C40.0498 47.4525 37.0348 49.5 33.6373 49.5H20.3623C16.9423 49.5 13.9498 47.4525 12.7123 44.28C11.4748 41.085 12.3298 37.5525 14.8498 35.2575L23.9623 27H30.0598L39.1498 35.2575C41.6698 37.5525 42.5023 41.085 41.2873 44.28Z"
                      fill="#B88B66"
                    />
                    <path
                      id="Vector_2"
                      d="M31.095 40.815H22.905C22.05 40.815 21.375 40.1175 21.375 39.285C21.375 38.43 22.0725 37.755 22.905 37.755H31.095C31.95 37.755 32.625 38.4525 32.625 39.285C32.625 40.1175 31.9275 40.815 31.095 40.815Z"
                      fill="#B88B66"
                    />
                    <path
                      id="Vector_3"
                      d="M41.2875 9.72C40.05 6.5475 37.035 4.5 33.6375 4.5H20.3625C16.965 4.5 13.95 6.5475 12.7125 9.72C11.4975 12.915 12.33 16.4475 14.8725 18.7425L23.9625 27H30.06L39.15 18.7425C41.67 16.4475 42.5025 12.915 41.2875 9.72ZM31.095 16.2675H22.905C22.05 16.2675 21.375 15.57 21.375 14.7375C21.375 13.905 22.0725 13.2075 22.905 13.2075H31.095C31.95 13.2075 32.625 13.905 32.625 14.7375C32.625 15.57 31.9275 16.2675 31.095 16.2675Z"
                      fill="#B88B66"
                    />
                  </g>
                </g>
              </svg>
              <p>Оперативные сроки выполнения работ</p>
            </div>
            <p>
              Благодаря высокой квалификации наших специалистов, мы обеспечиваем
              быстрое и качественное выполнение всех работ.
            </p>
          </div>
        </div>
        <div className="c2 why_chose_cards"></div>
        <div className="c3 why_chose_cards">
          <div className="why_chose_text_conteiner">
            <div className="paragraph_svg">
              <svg
                width="55rem"
                height="54rem"
                viewBox="0 0 55 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="vuesax/bulk/profile-2user">
                  <g id="profile-2user">
                    <path
                      id="Vector"
                      opacity="0.4"
                      d="M20.9165 4.5C15.0215 4.5 10.229 9.2925 10.229 15.1875C10.229 20.97 14.7515 25.65 20.6465 25.8525C20.8265 25.83 21.0065 25.83 21.1415 25.8525C21.1865 25.8525 21.209 25.8525 21.254 25.8525C21.2765 25.8525 21.2765 25.8525 21.299 25.8525C27.059 25.65 31.5815 20.97 31.604 15.1875C31.604 9.2925 26.8115 4.5 20.9165 4.5Z"
                      fill="#B88B66"
                    />
                    <path
                      id="Vector_2"
                      d="M32.3467 31.8375C26.0692 27.6525 15.8317 27.6525 9.50916 31.8375C6.65166 33.75 5.07666 36.3375 5.07666 39.105C5.07666 41.8725 6.65166 44.4375 9.48666 46.3275C12.6367 48.4425 16.7767 49.5 20.9167 49.5C25.0567 49.5 29.1967 48.4425 32.3467 46.3275C35.1817 44.415 36.7567 41.85 36.7567 39.06C36.7342 36.2925 35.1817 33.7275 32.3467 31.8375Z"
                      fill="#B88B66"
                    />
                    <path
                      id="Vector_3"
                      opacity="0.4"
                      d="M45.6441 16.515C46.0041 20.88 42.8991 24.705 38.6016 25.2225C38.5791 25.2225 38.5791 25.2225 38.5566 25.2225H38.4891C38.3541 25.2225 38.2191 25.2225 38.1066 25.2675C35.9241 25.38 33.9216 24.6825 32.4141 23.4C34.7316 21.33 36.0591 18.225 35.7891 14.85C35.6316 13.0275 35.0016 11.3625 34.0566 9.94498C34.9116 9.51748 35.9016 9.24748 36.9141 9.15748C41.3241 8.77498 45.2616 12.06 45.6441 16.515Z"
                      fill="#B88B66"
                    />
                    <path
                      id="Vector_4"
                      d="M50.144 37.3275C49.964 39.51 48.569 41.4 46.229 42.6825C43.979 43.92 41.144 44.505 38.3315 44.4375C39.9515 42.975 40.8965 41.1525 41.0765 39.2175C41.3015 36.4275 39.974 33.75 37.319 31.6125C35.8115 30.42 34.0565 29.475 32.144 28.7775C37.1165 27.3375 43.3715 28.305 47.219 31.41C49.289 33.075 50.3465 35.1675 50.144 37.3275Z"
                      fill="#B88B66"
                    />
                  </g>
                </g>
              </svg>
              <p>Индивидуальный подход к каждому клиенту</p>
            </div>
            <p>
              Каждый проект для нас уникален, и мы стараемся максимально учесть
              все пожелания и требования клиента.
            </p>
          </div>
        </div>
        <div className="c4 why_chose_cards"></div>
        <div className="c5 why_chose_cards">
          <div className="why_chose_text_conteiner">
            <div className="paragraph_svg">
              <svg
                width="54rem"
                height="54rem"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="vuesax/bulk/award">
                  <g id="award">
                    <path
                      id="Vector"
                      opacity="0.4"
                      d="M40.3876 17.3475L40.0726 17.1675L36.9451 15.3675L30.4876 11.6325C28.5076 10.485 25.4926 10.485 23.5126 11.6325L17.0551 15.3675L13.9276 17.19L13.5226 17.415C9.4951 20.115 9.2251 20.61 9.2251 24.9525V35.325C9.2251 39.6675 9.4951 40.1625 13.6126 42.93L23.5126 48.645C24.5026 49.23 25.7401 49.5 27.0001 49.5C28.2376 49.5 29.4976 49.23 30.4876 48.645L40.4776 42.8625C44.5051 40.1625 44.7751 39.6675 44.7751 35.325V24.9525C44.7751 20.61 44.5051 20.115 40.3876 17.3475Z"
                      fill="#B88B66"
                    />
                    <path
                      id="Vector_2"
                      d="M13.9277 17.19L17.0552 15.3675L23.2202 11.8125L23.5127 11.6325C25.4927 10.485 28.5077 10.485 30.4877 11.6325L30.7802 11.8125L36.9452 15.3675L40.0727 17.1675V12.3525C40.0727 7.29 37.2827 4.5 32.2202 4.5H21.7577C16.6952 4.5 13.9277 7.29 13.9277 12.3525V17.19Z"
                      fill="#B88B66"
                    />
                    <path
                      id="Vector_3"
                      d="M33.3899 30.015L31.9949 31.725C31.7699 31.9725 31.6124 32.4675 31.6349 32.805L31.7699 35.01C31.8599 36.36 30.8924 37.0575 29.6324 36.5625L27.5849 35.7525C27.2699 35.64 26.7299 35.64 26.4149 35.7525L24.3674 36.5625C23.1074 37.0575 22.1399 36.36 22.2299 35.01L22.3649 32.805C22.3874 32.4675 22.2299 31.9725 22.0049 31.725L20.6099 30.015C19.7324 28.98 20.1149 27.8325 21.4199 27.495L23.5574 26.955C23.8949 26.865 24.2999 26.55 24.4799 26.2575L25.6724 24.4125C26.4149 23.265 27.5849 23.265 28.3274 24.4125L29.5199 26.2575C29.6999 26.55 30.1049 26.865 30.4424 26.955L32.5799 27.495C33.8849 27.8325 34.2674 28.98 33.3899 30.015Z"
                      fill="#B88B66"
                    />
                  </g>
                </g>
              </svg>
              <p>Строим бассейны и сауны высочайшего качества</p>
            </div>
            <p>
              Мы предлагаем строительство бассейнов и саун с использованием
              самых современных технологий и материалов.
            </p>
          </div>
        </div>
        <div className="c6 why_chose_cards">
          <div className="why_chose_text_conteiner">
            <div className="paragraph_svg">
              <svg
                width="55rem"
                height="54rem"
                viewBox="0 0 55 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="vuesax/bulk/shield">
                  <g id="shield">
                    <path
                      id="Vector"
                      opacity="0.4"
                      d="M46.2556 15.66V22.05L16.9831 43.515L11.0656 39.0825C9.60314 37.98 8.41064 35.6175 8.41064 33.795V15.66C8.41064 13.14 10.3456 10.35 12.7081 9.47249L25.0156 4.85999C26.2981 4.38749 28.3681 4.38749 29.6506 4.85999L41.9581 9.47249C44.3206 10.35 46.2556 13.14 46.2556 15.66Z"
                      fill="#B88B66"
                    />
                    <path
                      id="Vector_2"
                      d="M46.2562 25.1324V33.7949C46.2562 35.6174 45.0637 37.9799 43.6012 39.0824L31.2937 48.2849C30.2137 49.0949 28.7737 49.4999 27.3337 49.4999C25.8937 49.4999 24.4537 49.0949 23.3737 48.2849L19.0537 45.0674L46.2562 25.1324Z"
                      fill="#B88B66"
                    />
                  </g>
                </g>
              </svg>
              <p>Гарантия безопасности и надежности!</p>
            </div>
            <p>
              Все наши бассейны и сауны проходят строгий контроль качества на
              каждом этапе строительства.
            </p>
          </div>
        </div>
        <div className="c7 why_chose_cards">
          <div className="why_chose_text_conteiner">
            <div className="paragraph_svg">
              <svg
                width="54rem"
                height="54rem"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="vuesax/bulk/layer">
                  <g id="layer">
                    <path
                      id="Vector"
                      d="M43.5823 11.0025L30.3973 5.13002C28.4623 4.27502 25.5373 4.27502 23.6023 5.13002L10.4173 11.0025C7.08729 12.4875 6.59229 14.5125 6.59229 15.5925C6.59229 16.6725 7.08729 18.6975 10.4173 20.1825L23.6023 26.055C24.5698 26.4825 25.7848 26.7075 26.9998 26.7075C28.2148 26.7075 29.4298 26.4825 30.3973 26.055L43.5823 20.1825C46.9123 18.6975 47.4073 16.6725 47.4073 15.5925C47.4073 14.5125 46.9348 12.4875 43.5823 11.0025Z"
                      fill="#B88B66"
                    />
                    <path
                      id="Vector_2"
                      opacity="0.4"
                      d="M27.0002 38.34C26.1452 38.34 25.2902 38.16 24.5027 37.8225L9.33771 31.0725C7.02022 30.0375 5.22021 27.27 5.22021 24.7275C5.22021 23.805 5.96271 23.0625 6.88521 23.0625C7.80771 23.0625 8.55021 23.805 8.55021 24.7275C8.55021 25.9425 9.56271 27.5175 10.6877 28.0125L25.8527 34.7625C26.5727 35.0775 27.4052 35.0775 28.1252 34.7625L43.2902 28.0125C44.4152 27.5175 45.4277 25.965 45.4277 24.7275C45.4277 23.805 46.1702 23.0625 47.0927 23.0625C48.0152 23.0625 48.7577 23.805 48.7577 24.7275C48.7577 27.2475 46.9577 30.0375 44.6402 31.0725L29.4752 37.8225C28.7102 38.16 27.8552 38.34 27.0002 38.34Z"
                      fill="#B88B66"
                    />
                    <path
                      id="Vector_3"
                      opacity="0.4"
                      d="M27.0002 49.5C26.1452 49.5 25.2902 49.32 24.5027 48.9825L9.33771 42.2325C6.84021 41.13 5.22021 38.6325 5.22021 35.8875C5.22021 34.965 5.96271 34.2225 6.88521 34.2225C7.80771 34.2225 8.55021 34.965 8.55021 35.8875C8.55021 37.305 9.38271 38.5875 10.6877 39.1725L25.8527 45.9225C26.5727 46.2375 27.4052 46.2375 28.1252 45.9225L43.2902 39.1725C44.5727 38.61 45.4277 37.305 45.4277 35.8875C45.4277 34.965 46.1702 34.2225 47.0927 34.2225C48.0152 34.2225 48.7577 34.965 48.7577 35.8875C48.7577 38.6325 47.1377 41.1075 44.6402 42.2325L29.4752 48.9825C28.7102 49.32 27.8552 49.5 27.0002 49.5Z"
                      fill="#B88B66"
                    />
                  </g>
                </g>
              </svg>
              <p>Широкий ассортимент бассейнов и саун на любой вкус</p>
            </div>
            <p>
              У нас вы найдете различные виды бассейнов и саун, включая
              каркасные, бетонные, композитные и другие.
            </p>
          </div>
        </div>
        <div className="c8 why_chose_cards">
          <div className="why_chose_text_conteiner">
            <div className="paragraph_svg">
              <svg
                width="54rem"
                height="54rem"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="vuesax/bulk/coin">
                  <g id="coin">
                    <path
                      id="Vector"
                      opacity="0.4"
                      d="M22.4775 40.455C32.4062 40.455 40.455 32.4062 40.455 22.4775C40.455 12.5488 32.4062 4.5 22.4775 4.5C12.5488 4.5 4.5 12.5488 4.5 22.4775C4.5 32.4062 12.5488 40.455 22.4775 40.455Z"
                      fill="#B88B66"
                    />
                    <path
                      id="Vector_2"
                      d="M49.4327 35.9776C49.4327 43.4026 43.4027 49.4326 35.9777 49.4326C31.3877 49.4326 27.3602 47.1376 24.9302 43.6501C34.7402 42.5476 42.5477 34.7401 43.6502 24.9301C47.1377 27.3601 49.4327 31.3876 49.4327 35.9776Z"
                      fill="#B88B66"
                    />
                    <path
                      id="Vector_3"
                      d="M25.7625 21.8475L20.3625 19.9575C19.8225 19.7775 19.71 19.7325 19.71 18.945C19.71 18.36 20.115 17.8875 20.6325 17.8875H24.0075C24.7275 17.8875 25.3125 18.54 25.3125 19.35C25.3125 20.2725 26.0775 21.0375 27 21.0375C27.9225 21.0375 28.6875 20.2725 28.6875 19.35C28.6875 16.7625 26.685 14.6475 24.1875 14.535V14.4225C24.1875 13.5 23.4225 12.735 22.5 12.735C21.5775 12.735 20.8125 13.4775 20.8125 14.4225V14.535H20.61C18.2475 14.535 16.3125 16.515 16.3125 18.9675C16.3125 21.105 17.2575 22.4775 19.215 23.1525L24.6375 25.0425C25.1775 25.2225 25.29 25.2675 25.29 26.055C25.29 26.64 24.885 27.1125 24.3675 27.1125H20.9925C20.2725 27.1125 19.6875 26.46 19.6875 25.65C19.6875 24.7275 18.9225 23.9625 18 23.9625C17.0775 23.9625 16.3125 24.7275 16.3125 25.65C16.3125 28.2375 18.315 30.3525 20.8125 30.465V30.6C20.8125 31.5225 21.5775 32.2875 22.5 32.2875C23.4225 32.2875 24.1875 31.5225 24.1875 30.6V30.4875H24.39C26.7525 30.4875 28.6875 28.5075 28.6875 26.055C28.6875 23.9175 27.72 22.545 25.7625 21.8475Z"
                      fill="#B88B66"
                    />
                  </g>
                </g>
              </svg>
              <p>Выгодные цены на строительство бассейнов и саун</p>
            </div>
            <p>
              Благодаря прямым поставкам материалов и оптимизации процессов, мы
              можем предложить нашим клиентам лучшие условия сотрудничества.
            </p>
          </div>
        </div>
        <div className="c9 why_chose_cards">
          <PopUpForm
            classButton={'button_hover'}
            nameButton={'Получить консультацию'}
          />
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUs;
