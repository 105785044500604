import React from 'react';
import './style_hammam.css';
//import PresentationSauna from './block_1_presentation_sauna/PresentationSauna';
import Header from '../../modules/Header/Header';
//import ReasonForSauna from './block_2_reason_for_hammam/ReasonForHammam';
//import DesignStagesSauna from './block_3_design_stages_hammam/DesignStagesHammam';
//import TypesOfSaunas from './block_4_types_of_hammam/TypesOfHammam';
import Footer from '../../modules/Footer/Footer';
import PresentationHammam from './block_1_presentation_hammam/PresentationHammam';
import ReasonForHammam from './block_2_reason_for_hammam/ReasonForHammam';
import DesignStagesHammam from './block_3_design_stages_hammam/DesignStagesHammam';
import TypesOfHammam from './block_4_types_of_hammam/TypesOfHammam';
import OurFinishedHammam from './block_5_our_finished_hammam/OurFinishedHammam';
//import OurFinishedHammam from './block_5_our_finished_hammam/OurFinishedHammam';
import TransitionPages from '../../modules/Transition_Pages/TransitionPages';

function Hammam() {
  return (
    <div className="hammam_conteiner">
      <Header />
      <PresentationHammam />
      <ReasonForHammam />
      <DesignStagesHammam />
      <TypesOfHammam />
      <OurFinishedHammam />
      <Footer />
    </div>
  );
}

export default TransitionPages(Hammam);
