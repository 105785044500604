import React from 'react';
import './style_presentation_spa_zone.css';

function PresentationSpaZone() {
  return (
    <div className="presentation_spa_zone_conteiner">
      <div className="presentation_spa_zone">
        {/* <div className="bread_crumbs">
          <p>Главная</p>
          <p>/</p>
          <p>Спа-зона</p>
        </div> */}
        <h1>СПА-Зона</h1>
        <p>
          Если вы хотите создать свою собственную спа-зону, обращайтесь к нам!
          Мы поможем вам выбрать оптимальное место для строительства и
          разработаем проект спа-зоны. Если вы хотите провести время с пользой
          для своего здоровья и красоты, то спа-зона – это то, что вам нужно.
        </p>
      </div>
    </div>
  );
}

export default PresentationSpaZone;
