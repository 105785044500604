import React from 'react';
import './style_about_concrete_material.css';
import img_wave_concrete from './img/WAWE.png';

function AboutConcreteMaterial() {
  return (
    <div className="about_concrete_material_conteiner">
      <h2>
        Бассейны из бетона могут быть различных форм и размеров, а также могут быть украшены
        различными элементами декора
      </h2>
      <div className="ocean_view_pool"></div>
      <p>
        Бетонные бассейны могут быть разных форм и размеров, и они идеально
        подходят для любого стиля двора или ландшафта. Однако, установка
        бетонного бассейна может быть более трудоемкой и дорогостоящей, чем
        установка других типов бассейнов. В целом, бетонные бассейны
        представляют собой отличное сочетание цены, качества и функциональности.
      </p>
      <img src={img_wave_concrete} alt="ocean_view_pool" />
    </div>
  );
}

export default AboutConcreteMaterial;
