import React from 'react';
import './style_advantage_composite_pool.css';

function AdvantageCompositePool() {
  return (
    <div className="advantage_composite_pool_conteiner">
      <div className="advantage_composite_pool">
        <h1>
          <span>преимущества</span> выбора
          <br /> композитных бассейнов
        </h1>
        <div className="advantage_composite_pool_text_img">
          <div className="img_acopc_1 img_acopc advantage_composite_pool_card"></div>
          <div className="tc1 text_acopc advantage_composite_pool_card">
            <h3>Легкость</h3>
            <p>
              Композитные бассейны намного легче традиционных бассейнов из
              бетона или стали, что упрощает их транспортировку и установку.
            </p>
          </div>
          <div className="tc2 text_acopc advantage_composite_pool_card">
            <h3>Прочность</h3>
            <p>
              Композитные бассейны обладают высокой прочностью и долговечностью,
              что обеспечивает длительный срок службы.
            </p>
          </div>
          <div className="img_acopc_2 img_acopc advantage_composite_pool_card"></div>
          <div className="img_acopc_3 img_acopc advantage_composite_pool_card"></div>
          <div className="tc3 text_acopc advantage_composite_pool_card">
            <h3>Устойчивость к коррозии</h3>
            <p>
              Композиты не ржавеют и не корродируют, что обеспечивает сохранение
              внешнего вида бассейна на протяжении многих лет.
            </p>
          </div>
          <div className="tc4 text_acopc advantage_composite_pool_card">
            <h3>Быстрая установка</h3>
            <p>
              Установка композитного бассейна занимает значительно меньше
              времени, по сравнению с установкой бетонного или стального
              бассейна.
            </p>
          </div>
          <div className="img_acopc_4 img_acopc advantage_composite_pool_card"></div>
          <div className="img_acopc_5 img_acopc advantage_composite_pool_card"></div>
          <div className="tc5 text_acopc advantage_composite_pool_card">
            <h3>Устойчивость к химикатам</h3>
            <p>
              Композитные бассейны можно обрабатывать любыми видами химикатов
              без риска повреждения поверхности.
            </p>
          </div>
          <div className="tc6 text_acopc advantage_composite_pool_card">
            <h3>Эстетический вид</h3>
            <p>
              Композитные бассейны имеют гладкую и красивую поверхность, которая
              может быть окрашена в любой цвет по выбору заказчика.
            </p>
          </div>
          <div className="img_acopc_6 img_acopc advantage_composite_pool_card"></div>
        </div>
      </div>
    </div>
  );
}

export default AdvantageCompositePool;
