import Slider from 'react-slick';
import './style_slick_slider_center.css';
import { SlArrowRight, SlArrowLeft } from 'react-icons/sl';

function SampleNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className={'sscbn'}
      style={{ ...style, display: 'block', background: 'rgb(255, 255, 255)' }}
      onClick={onClick}
    >
      <SlArrowRight color="black" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className={'sscbp'}
      style={{ ...style, display: 'block', background: 'rgb(255, 255, 255)' }}
      onClick={onClick}
    >
      <SlArrowLeft color="black" />
    </div>
  );
}

function SlickSliderCenter({ data }) {
  const settings = {
    appendDots: (dots) => (
      <div
        style={{
          maxWidth: '1920px',
          width: '1920rem',
        }}
      >
        {dots}
      </div>
    ),

    dots: true,
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {data.map((obj) => {
          return <img key={obj.id} src={obj.img} alt={obj.id}></img>;
        })}
      </Slider>
    </div>
  );
}

export default SlickSliderCenter;
