import React from 'react';
import PresentationConcretePool from './block_1_presentation_concrete_pool/PresentationConcretePool';
import AdvantageConcretePool from './block_2_advantage_concrete_pool/AdvantageConcretePool';
import AboutConcreteMaterial from './block_3_about_concrete_material/AboutConcreteMaterial';
//import TechnicalSelectionConcretePool from './block_4_technical_selection_concrete_pool/TechnicalSelectionConcretePool';
//import ShapesConcretePools from './block_5_shapes_concrete_pools/ShapesConcretePools';
//import ColorSelectorConcretePool from './block_6_color_selector_concrete_pool/ColorSelectorConcretePool';
import Footer from '../../../modules/Footer/Footer';
import TemporaryComponentSlickSliderPools from '../temporary_component_slick_slider_pools/TemporaryComponentSlickSliderPools';

function ConcretePool() {
  return (
    <>
      <PresentationConcretePool />
      <AdvantageConcretePool />
      <AboutConcreteMaterial />
      {/* <TechnicalSelectionConcretePool />
      <ShapesConcretePools />
      <ColorSelectorConcretePool /> */}
      <TemporaryComponentSlickSliderPools data_name={'concrete'} />
      <Footer />
    </>
  );
}

export default ConcretePool;
