const data_our_finished_hammam = [
  {
    id: '1',
    img: require('./img/a13674d2cc00d3ff14568c9f903c1c95.png'),
  },
  {
    id: '2',
    img: require('./img/228b906cf9f89fc521aa7ae1d1bc9951.png'),
  },
  {
    id: '3',
    img: require('./img/10C95ABD-F4E7-4BFE-A6AB-DE711AFBC90B.png'),
  },
  {
    id: '4',
    img: require('./img/23D36706-61D9-4D49-9B86-54A68BF48A50.png'),
  },
  {
    id: '5',
    img: require('./img/3DC53075-D599-4EE3-81D4-E9376129581F.png'),
  },
  {
    id: '6',
    img: require('./img/802A35CE-B76D-4393-B24F-F18AB0FFB66A.png'),
  },
  {
    id: '7',
    img: require('./img/53AA36B5-76CD-496E-9490-C9BB2F0497C4.png'),
  },
  {
    id: '8',
    img: require('./img/905E25D5-109E-496C-BB71-19E091A1EB3E.png'),
  },
  {
    id: '9',
    img: require('./img/F059323D-AAE6-4FC7-9CA8-2161B3DF6C49.png'),
  },
  {
    id: '10',
    img: require('./img/213DA3F2-CD51-4DE1-97FC-F5B479439A27.png'),
  },
  {
    id: '11',
    img: require('./img/97A0DE0D-0FB3-4EEC-B9B5-0C9CE6BD8DC3.png'),
  },
];

export default data_our_finished_hammam;
