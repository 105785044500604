import React from 'react';
import { Outlet } from 'react-router-dom';
//import BurgerMenu from '../modules/Burger_Menu/BurgerMenu';

function MainLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default MainLayout;
