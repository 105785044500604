import React from 'react';
import './style_burger_menu.css';
import { Link } from 'react-router-dom';

function BurgerMenu({ handleClick }) {
  return (
    <div className="burger_menu_conteiner">
      <div className="burger_menu">
        <div className="burger_menu_left_side">
          <div className="burger_menu_by_general_page">
            <Link onClick={handleClick} to="/pool_selection">
              <h2>Бассейны</h2>
            </Link>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                handleClick();
              }}
              to="/sauna"
            >
              <h2>Сауны</h2>
            </Link>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                handleClick();
              }}
              to="/hammam"
            >
              <h2>Хаммамы</h2>
            </Link>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                handleClick();
              }}
              to="/spa_zone"
            >
              <h2>Спа-зона</h2>
            </Link>
          </div>
        </div>
        <div className="burger_menu_contacts">
            <a href="tel:+79092174447">+7 909 217-44-47</a>
            <a href="mailto:info@grangota.ru">info@grangota.ru</a>
            <p>г. Воронеж, пр. Труда д.68</p>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                handleClick();
              }}
              to="/contacts"
            >
              <p>Посмотреть на карте</p>
            </Link>
        </div>
        <div className="burger_menu_right_side">
          <div className="burger_menu_by_min_page">
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                handleClick();
              }}
              to="/about_company"
            >
              <h3>О компании</h3>
            </Link>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                handleClick();
              }}
              to="/contacts"
            >
              <h3>Контакты</h3>
            </Link>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                handleClick();
              }}
              to="/privacy_policy"
            >
              <h3>Политика конфиденциальности</h3>
            </Link>
          </div>
          {/* <div className="burger_menu_socials">
            <a href="www.google.com">LinkedIn</a>
            <a href="www.google.com">Instagram</a>
            <a href="www.google.com">Telegram</a>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default BurgerMenu;
