import React from 'react'
import './style_office_photo.css'
import { Parallax } from 'react-scroll-parallax'

function OfficePhoto() {
  return (
    <div className='office_photo_conteiner'>
        <Parallax 
        translateY={[-20,20]}
        easing={'easeInOut'}
        speed={10}
        
        >
          <div className='office_photo_parallax'></div>
        </Parallax>     
    </div>
  )
}

export default OfficePhoto