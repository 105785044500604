import React from 'react';
import './style_reason_for_sauna.css';
import wave from './img/WAWE.png';

function ReasonForSauna() {
  return (
    <div className="reason_for_sauna_conteiner">
      <img src={wave} alt="graph_wave" className="background_wave"></img>
      <div className="reason_for_sauna">
        <div className="reason_for_sauna_text">
          <h1>ТОП 3</h1>
          <h2>
            <span>причин почему нужна сауна</span> в загородном участке
          </h2>
        </div>
        <div className="reason_for_sauna_blocks">
          <div className="reason_for_sauna_block_img_1 rfsbi"></div>
          <div className="reason_for_sauna_block_text rfsbt_1">
            <h3>Улучшение здоровья</h3>
            <p>
              Сауна помогает улучшить кровообращение, очистить кожу, расслабить
              мышцы и снять стресс. Это особенно полезно для тех, кто живет в
              постоянном стрессе и ведет малоподвижный образ жизни. Регулярное
              посещение сауны также помогает укрепить иммунную систему.
            </p>
          </div>
          <div className="reason_for_sauna_block_text rfsbt_2">
            <h3>Общение и развлечения</h3>
            <p>
              Сауна может стать отличным местом для проведения времени с
              друзьями или семьей. Она может служить местом для отдыха,
              расслабления и общения. В сауне можно устроить вечеринки, игры или
              просто провести время за приятной беседой.
            </p>
          </div>
          <div className="reason_for_sauna_block_img_2 rfsbi"></div>
          <div className="reason_for_sauna_block_img_3 rfsbi"></div>
          <div className="reason_for_sauna_block_text rfsbt_3">
            <h3>Восстановление после физической активности</h3>
            <p>
              Сауна также может быть полезна для спортсменов и людей, ведущих
              активный образ жизни. Она помогает расслабить мышцы после
              тренировки и уменьшить боль. Сауна также способствует выведению
              токсинов из организма.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReasonForSauna;
