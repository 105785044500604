import React, { useState } from 'react';
import './style_types_of_saunas.css';
import wood_sauna from './data/data_wood_sauna';
import electro_sauna from './data/data_electro_sauna';

function TypesOfSaunas() {
  const [activeTypeSauna, setActiveTypeSauna] = useState(wood_sauna);

  function handleChangeTypeSauna(e) {
    if (e.target.value === 'wood') {
      setActiveTypeSauna(wood_sauna);
    } else if (e.target.value === 'electro') {
      setActiveTypeSauna(electro_sauna);
    }
  }

  return (
    <div className="types_of_saunas_conteiner">
      <div className="types_of_saunas">
        <h2>Виды саун</h2>
        <div className="choose_types_of_saunas_buttons">
          <button value={'wood'} onClick={handleChangeTypeSauna}>
            Деревянная сауна
          </button>
          <button value={'electro'} onClick={handleChangeTypeSauna}>
            электрическая сауна
          </button>
        </div>
        <div className="types_of_saunas_img">
          {activeTypeSauna.map((data) => {
            return (
              <div
                key={data.id}
                className={`type_of_sauna_img_${data.id} tosi`}
                style={{ backgroundImage: `url(${data.sauna_img})` }}
              ></div>
            );
          })}
        </div>
        {activeTypeSauna === wood_sauna ? <p>
          Деревянные сауны строятся из различных пород дерева, таких как сосна,
          ель, кедр и липа. При строительстве сауны важно правильно выбрать
          дерево, так как разные породы имеют свои преимущества и недостатки.
          Также важно уделить внимание качеству обработки дерева и его защите от
          влаги и насекомых.
        </p> : <p>Электрические сауны обеспечивают комфорт и удобство без необходимости раскалывания дров. Их преимущества включают быстрое нагревание, регулируемую температуру и меньшую заботу о поддержании источника тепла.</p> }
        
      </div>
    </div>
  );
}

export default TypesOfSaunas;
