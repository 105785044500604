import React, { /*useEffect,*/ useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import axios from 'axios';
//import 'react-phone-number-input/style.css';
//import Input from 'react-phone-number-input/input';
//import { getCountryCallingCode } from 'react-phone-number-input/input';
import ReCAPTCHA from 'react-google-recaptcha';
import './style_form_footer.css';

import PhoneInput from 'react-phone-number-input/react-hook-form-input';

const recaptchaRef = React.createRef();

function FormFooter({nameForm}) {
  const [phoneValue, setPhoneValue] = useState();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = (data) => {
    recaptchaRef.current.execute().then((e) => {
      //console.log('executeAsync promise - Captcha value:', value);
      const inputVal = e;
      const token = recaptchaRef.current.getValue();
      recaptchaRef.current.reset();

      axios
        .post('https://grangota.ru:8080/post', {
          inputVal,
          token,
        })
        .then((res) => {
          // результат оценки на бота или человека
          console.log(res.data);
          
          if (res.data.score >= 0.7) {
            (function(){
              window.ym(96885049,'reachGoal','goal_achieved');
            }());
            alert('Спасибо! Скоро мы свяжемся с Вами!')
            //console.log(data);
            axios.post('https://grangota.ru:3030/post', {
              data
            })
            .then((res) => {
              console.log(res)
              
            })
            //отправляю ФОРМУ на ПОЧТУ
          } else if (res.data.score < 0.7) {
            alert('Упс! Возможно вы Робот! Если это не так, позвоните нам и мы всё решим)')
          }
        })
        .catch((error) => {
          alert('Что-то пошло не так, попробуйте еще раз.');
          console.log(error);
        });
    });
    reset();

    
  };

  // // const regexpPhoneValidate =
  // //   /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm;
  // const reg = /^\+?7([348]\d{9})$/;
  // const tel = '+78005554600';
  // let FFF = reg.exec(tel);
  // console.log(FFF);
  // // если null то номер не (русский) валидный
  // console.log('ЫЫЫ');

  // Использовать useEffect? что бы обновлять значение в инпут
  function handleSetPhoneValue(e) {
    setPhoneValue(e);
  }

  // useEffect(() => {
  //   document.querySelector('.input_tel').value = phoneValue;
  // });

  return (
    <div className="form_footer">
      <div className="application_form_button">
        <div className="application_text">
          <h2>ОСТАВЬТЕ ЗАЯВКУ</h2>
          <p>
            Оставьте заявку и получите подробную консультацию по проектированию
            и строительству бассейнов, хаммамов, саун и Спа-зон
          </p>
        </div>
        <div className="form_input">
          <form className={nameForm} onSubmit={handleSubmit(onSubmit)} id={nameForm}>
            {/* register your input into the hook by invoking the "register" function */}
            <input
              className="input_name_form input"
              placeholder="Ваше Имя"
              {...register('firstName', {
                required: 'Поле Имя не заполнено',
              })}
            />
            {/* include validation with required or other standard HTML validation rules */}
            <div className="input_tel_form_conteiner">
              {/* <p className="defCountry">{'+' + getCountryCallingCode('RU')}</p> */}
              <PhoneInput
                className="input_tel input"
                // {...register('phoneNumber', {
                //   required: 'Укажите номер Вашего телефона',
                // })}
                name="phoneNumber"
                rules={{ required: 'Укажите номер Вашего телефона' }}
                value={phoneValue}
                control={control}
                placeholder="+7 900 000-00-00"
                //international={true}
                //countryCallingCodeEditable={true}
                //country="RU"
                defaultCountry="RU"
                onChange={handleSetPhoneValue}
              />
            </div>
            {/* errors will return when field validation fails  */}
            {/*errors.exampleRequired && <span>This field is required</span>*/}
          </form>
          <div style={{ fontSize: '10rem', color: 'red' }}>
            {errors?.firstName && <p>Заполните поле "Ваше Имя"</p>}
          </div>
          <div style={{ fontSize: '10rem', color: 'red' }}>
            {errors?.phoneNumber && (
              <p>{errors?.phoneNumber?.message || 'Попробуйте еще раз!'}</p>
            )}
          </div>
        </div>
        <div className="button_send_privacy_policy">
          <input type="submit" className={'button_hover'} form={nameForm} />{' '}
          {/*form = aaa привязываю с формой выше (id = aaa) */}
          <p>
            Нажимаяя кнопку “Отправить” вы соглашаетесь с
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                
              }}
              to="/privacy_policy"
              target='_blank'
            >
            &nbsp;Политикой конфиденциальности
            </Link>
          </p>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LdjGlspAAAAALoahoeo9SRZyUqDwDKbGlsnUlK3"
          />
        </div>
      </div>
      <div className="form_footer_img"></div>
      <div className="reCAPTCHA_branding_visibly">
        <p>This site is protected by reCAPTCHA and the Google</p>
        <a href="https://policies.google.com/privacy">Privacy Policy</a>{' '}
        and&nbsp;
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </div>
  );
}

export default FormFooter;

/* <input
              value={phoneValue}
              className="input_tel_form input"
              defaultValue="+7 000"
              {...register('phoneNumber', {
                required: true,
                minLength: {
                  value: 12,
                  message: 'Проверьте номер',
                },
                pattern: {
                  message: 'Введите корректный номер',
                  value: /^\+?7(\d{10})$/,
                },
                onChange: (e) => {
                  console.log(e.target.value);
                  setPhoneValue(e.target.value);
                },
              })}
            /> */
