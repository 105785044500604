import React from 'react';
import './style_reason_for_hammam.css';
import wave from './img/WAWE.png';

function ReasonForHammam() {
  return (
    <div className="reason_for_hammam_conteiner">
      <img src={wave} alt="graph_wave" className="background_wave"></img>
      <div className="reason_for_hammam">
        <div className="reason_for_hammam_text">
          <h1>ТОП 3</h1>
          <h2>
            <span>причин почему нужен хаммам</span> в загородном участке
          </h2>
        </div>
        <div className="reason_for_hammam_blocks">
          <div className="reason_for_hammam_block_img_1 rfhbi"></div>
          <div className="reason_for_hammam_block_text_1 rfhbt">
            <h3>Польза для здоровья</h3>
            <p>
              Хаммамы обладают множеством преимуществ для здоровья, включая
              улучшение кровообращения, облегчение стресса и усталости,
              улучшение состояния кожи и облегчение болей в мышцах. Высокая
              влажность и умеренная температура хаммама помогают открыть поры
              кожи, что способствует ее очищению и омоложению.
            </p>
          </div>
          <div className="reason_for_hammam_block_text_2 rfhbt">
            <h3>Релаксация</h3>
            <p>
              Хаммам - это место для релаксации и отдыха. Мягкий теплый пар и
              уютная атмосфера способствуют расслаблению, снятию стресса и
              улучшению настроения. Это идеальное место для тех, кто хочет
              забыть о повседневных заботах и насладиться спокойствием.
            </p>
          </div>
          <div className="reason_for_hammam_block_img_2 rfhbi"></div>
          <div className="reason_for_hammam_block_img_3 rfhbi"></div>
          <div className="reason_for_hammam_block_text_3 rfhbt">
            <h3>Традиция</h3>
            <p>
              В некоторых культурах хаммам является важной частью традиций и
              обычаев. Посещение хаммама может быть социальным мероприятием, где
              люди собираются вместе, чтобы пообщаться, отдохнуть и получить
              пользу для здоровья.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReasonForHammam;
