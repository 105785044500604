import React from 'react';
import './style_about_composite_material.css';
import img_wave_composite from './img/WAWE.png';

function AboutCompositeMaterial() {
  return (
    <div className="about_composite_material_conteiner">
      <h2>
        Композитный материал позволяет создавать бассейны с гладкой
        поверхностью, которая выглядит очень красиво и современно
      </h2>
      <div className="composite_ocean_view_pool"></div>
      <p>
        Благодаря гибкости композитных материалов, композитные бассейны
        могут быть изготовлены в различных формах и размерах, включая круглые,
        овальные, прямоугольные и многоугольные формы. Это позволяет подобрать
        бассейн, который идеально впишется в ваш двор или помещение.
      </p>
      <img src={img_wave_composite} alt="ocean_view_pool" />
    </div>
  );
}

export default AboutCompositeMaterial;
