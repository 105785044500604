import React from 'react';
import './style_privacy_policy.css';
import Header from '../../modules/Header/Header';
import Footer from '../../modules/Footer/Footer';
import PrivacyPolicyReferenceText from './block_1_privacy_policy_reference_text/PrivacyPolicyReferenceText';
import TransitionPages from '../../modules/Transition_Pages/TransitionPages';

function PrivacyPolicy() {
  return (
    <div className="privacy_policy_conteiner">
      <Header />
      <PrivacyPolicyReferenceText />
      <Footer />
    </div>
  );
}

export default TransitionPages(PrivacyPolicy);
