import React, { useState } from 'react';
import './style_types_of_hammam.css';
import modern_hammam from './data/data_modern_hammam';
import east_hammam from './data/data_east_hammam';

function TypesOfHammam() {
  const [activeTypeHammam, setActiveTypeHammam] = useState(modern_hammam);

  function handleChangeTypeHammam(e) {
    if (e.target.value === 'modern') {
      setActiveTypeHammam(modern_hammam);
    } else if (e.target.value === 'east') {
      setActiveTypeHammam(east_hammam);
    }
  }

  return (
    <div className="types_of_hammams_conteiner">
      <div className="types_of_hammams">
        <h2>Виды хаммамов</h2>
        <div className="choose_types_of_hammams_buttons">
          <button value={'modern'} onClick={handleChangeTypeHammam}>
            Современныйх хаммам
          </button>
          <button value={'east'} onClick={handleChangeTypeHammam}>
            восточный хаммам
          </button>
        </div>
        <div className="types_of_hammams_img">
          {activeTypeHammam.map((data) => {
            return (
              <div
                key={data.id}
                className={`type_of_hammam_img_${data.id} tohi`}
                style={{ backgroundImage: `url(${data.hammam_img})` }}
              ></div>
            );
          })}
        </div>
        {activeTypeHammam === modern_hammam ? <p>Современный хаммам представляет собой современное пространство, где традиционные восточные ритуалы сочетаются с инновационными технологиями и дизайном. Здесь вы найдете стильное оформление, интеллектуальное управление функциями, инновационное оборудование для сауны и парной, а также различные услуги спа.</p> : <p>Восточный хаммам представляет собой аутентичное культурное наследие Востока, где традиционные ритуалы ухода за телом и душой сочетаются с ароматерапией, массажем и уходом за кожей. Это место, где вы можете полностью расслабиться и отдохнуть в уютной и атмосферной обстановке, погрузившись в традиции и теплоту Востока.</p>}
      </div>
    </div>
  );
}

export default TypesOfHammam;
