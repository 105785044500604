import './App.css';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Contacts from './pages/contacts/Contacts';
import PrivacyPolicy from './pages/privacy_policy/PrivacyPolicy';
import AboutCompany from './pages/about_company/AboutCompany';
import SpaZone from './pages/spa_zone/SpaZone';
import Hammam from './pages/hammam/Hammam';
import Sauna from './pages/sauna/Sauna';
import GranGotaMain from './pages/gran_gota_main/GranGotaMain';
import PoolSelection from './pages/pool_selection/PoolSelection';
import MainLayout from './layouts/MainLayout';
import { AnimatePresence } from 'framer-motion';
import { ParallaxProvider } from 'react-scroll-parallax';
import NotFound from './pages/not_found/NotFound';

function App() {
  const location = useLocation();

  return (
    <ParallaxProvider>
    <div className="App">
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<MainLayout />}>
            <Route index={true} element={<GranGotaMain />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="about_company" element={<AboutCompany />} />
            <Route path="privacy_policy" element={<PrivacyPolicy />} />
            <Route path="spa_zone" element={<SpaZone />} />
            <Route path="hammam" element={<Hammam />} />
            <Route path="sauna" element={<Sauna />} />
            <Route path="pool_selection" element={<PoolSelection />} />
            <Route path="/404" element={ <NotFound /> } />
            <Route path='*' element={ <Navigate to="/404" replace />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </div>
    </ParallaxProvider>
  );
}

export default App;
