const data_our_finished_saunas = [
  {
    id: '1',
    img: require('./img/6d78b0ff9238eb5ad4dbf9c835ffa40b.png'),
  },
  {
    id: '2',
    img: require('./img/39d69bd578c720e4b730ff5870b7f8fe.png'),
  },
  {
    id: '3',
    img: require('./img/e76d87858d9df6fdce5cb66a94a913fb.png'),
  },
  {
    id: '4',
    img: require('./img/26F30EB5-AB12-4D2D-B72C-5FE3BF1E31E3.png'),
  },
  {
    id: '5',
    img: require('./img/34F038FD-4A89-46CE-92FC-E802AADBDF46.png'),
  },
  {
    id: '6',
    img: require('./img/34F9B89B-19AC-4F17-81D7-D6473E3FA0B4.png'),
  },
  {
    id: '7',
    img: require('./img/64E2D6B4-57D1-44F1-AE6F-FBE496E6DA43.png'),
  },
  {
    id: '8',
    img: require('./img/80BE462E-7F7D-475B-91B6-C74CBE5A3ACB.png'),
  },
  {
    id: '9',
    img: require('./img/93A8BD4B-A8D7-4D00-9F66-5696438AD85F.png'),
  },
  {
    id: '10',
    img: require('./img/979BE669-A32D-4614-9902-C6447D1477B3.png'),
  },
  {
    id: '11',
    img: require('./img/F92336F8-329B-4050-A99E-124E5F7E2045.png'),
  },
];

export default data_our_finished_saunas;
