import React from 'react';
import './style_presentation_composite_pool.css';
//import { AiOutlinePlus } from 'react-icons/ai';

function PresentationCompositePool() {
  return (
    <div
      id="composite_pool_anchor"
      className="presentation_composite_pool_conteiner"
    >
      <div className="presentation_composite_pool">
        {/* <div className="bread_crumbs">
          <p>Главная</p>
          <p>/</p>
          <p>Композиционные бассейны</p>
        </div> */}
        <h1>
        Композитные
          <br /> бассейны
        </h1>
        <div className="presentation_composite_pool_text">
          <p>
          Композитные бассейны имеют множество преимуществ перед
            традиционными бетонными или металлическими аналогами. Они более
            экономичны в установке и обслуживании.
          </p>
          <p>
            Кроме того, композитные бассейны обладают высокой устойчивостью к
            коррозии, температурным изменениям и ультрафиолетовому излучению.
          </p>
        </div>
        {/* <div className="more_details_button">
          <button>
            <AiOutlinePlus color="white" />
          </button>
          <p>Подробнее</p>
        </div> */}
      </div>
    </div>
  );
}

export default PresentationCompositePool;
