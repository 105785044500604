import React from 'react';
import './style_design_stages_hammam.css';
import DesignStagesSlider from '../../../modules/Design_Stages_Slider/DesignStagesSlider';

function DesignStagesHammam() {
  return (
    <div className="design_stages_hammam_conteiner">
      <DesignStagesSlider object={'hammam'} />
    </div>
  );
}

export default DesignStagesHammam;
