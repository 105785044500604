import React from 'react';
import './style_choose_spa_zone.css';
import PopUpForm from '../../../modules/PopUpForm/PopUpForm';

function ChooseSpaZone() {
  return (
    <div className="style_choose_spa_zone_conteiner">
      <div className="choose_spa_zone">
        <div className="snow_room spa_zone_card">
          <div className="snow_room_img cszi"></div>
          <div className="snow_room_text cszt">
            <div className="spa_zone_text_button_card">
              <h2 className="sztw">Снежная комната</h2>
              <div className="spa_zone_text">
                <h3 className="sztr">
                  Снежная комната - это уникальное помещение, созданное для
                  релаксации и снятия стресса.{' '}
                </h3>
                <p className="sztw">
                  В этой комнате поддерживается низкая температура и высокая
                  влажность, что создает ощущение пребывания на свежем воздухе в
                  зимнее время.{' '}
                </p>
                <p className="sztw">
                  Снежная комната помогает улучшить кровообращение, повысить
                  иммунитет и снять усталость. Кроме того, она может
                  использоваться для лечения некоторых заболеваний, таких как
                  астма и аллергия.
                </p>
              </div>
              <PopUpForm
                classButton={'button_hover'}
                nameButton={'Оставить заявку'}
              />
            </div>
          </div>
        </div>
        <div className="herbal_bath spa_zone_card">
          <div className="herbal_bath_text cszt">
            <div className="spa_zone_text_button_card">
              <h2 className="sztb">Травяная баня</h2>
              <div className="spa_zone_text">
                <h3 className="sztg">
                  Травяная баня - это место, где используются травы для создания
                  атмосферы релаксации и оздоровления.
                </h3>
                <p className="sztb">
                  В этой бане используются различные травы, которые помогают
                  улучшить кровообращение, очистить кожу и укрепить иммунную
                  систему. Кроме того, травяная баня может быть полезна для
                  лечения некоторых заболеваний, таких как артрит и ревматизм.
                  Если вы хотите отдохнуть и насладиться приятным ароматом трав,
                  то травяная баня - отличный выбор для вас.
                </p>
              </div>
              <PopUpForm
                classButton={'button_hover'}
                nameButton={'Оставить заявку'}
              />
            </div>
          </div>
          <div className="herbal_bath_img cszi"></div>
        </div>
        <div className="salt_room spa_zone_card">
          <div className="salt_room_img cszi"></div>
          <div className="salt_room_text cszt">
            <div className="spa_zone_text_button_card">
              <h2 className="sztw">Соляная комната</h2>
              <div className="spa_zone_text">
                <h3 className="sztr">Вместо отдыха на море</h3>
                <p className="sztw">
                  Соляная комната станет универсальным решением для тех, кто
                  стремится поддерживать крепкое здоровье и жизненный тонус, не
                  тратя на это много времени. Она дает возможность отказаться от
                  утомительных походов по врачам и приема большого количества
                  лекарств. Как показывает практика, по оздоровительному эффекту
                  и влиянию на организм человека пятнадцать сорокаминутных
                  сеансов равны полноценному двухнедельному отпуску на море или
                  в горах.
                </p>
              </div>
              <PopUpForm
                classButton={'button_hover'}
                nameButton={'Оставить заявку'}
              />
            </div>
          </div>
        </div>
        <div className="ice_hole spa_zone_card">
          <div className="ice_hole_text cszt">
            <div className="spa_zone_text_button_card">
              <h2 className="sztb">Ледяная прорубь</h2>
              <div className="spa_zone_text">
                <h3 className="sztg">
                  Ледяная прорубь - это помещение, в котором поддерживается
                  низкая температура.
                </h3>
                <p className="sztb">
                  Она используется для охлаждения тела после посещения сауны или
                  бани. Ледяная прорубь помогает улучшить кровообращение и
                  повысить иммунитет. Если вы хотите испытать на себе все
                  преимущества ледяной проруби, то оставьте заявку, обсудим
                  проект.
                </p>
              </div>
              <PopUpForm
                classButton={'button_hover'}
                nameButton={'Оставить заявку'}
              />
            </div>
          </div>
          <div className="ice_hole_img cszi"></div>
        </div>
      </div>
    </div>
  );
}

export default ChooseSpaZone;
