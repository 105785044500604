import React from 'react';
import './style_gran_gota_main.css';
import Header from '../../modules/Header/Header';
import PresentationSliderBlock from './block_1_presentation_slider_block/PresentationSliderBlock';
//import OursProjects from './block_2_ours_projects/OursProjects';
import NumberOfFinisedWorks from './block_3_number_of_finished_works/NumberOfFinisedWorks';
import WhyChooseUs from './block_4_why_choose_us/WhyChooseUs';
import LandscapeDesign from './block_5_landscape_design/LandscapeDesign';
//import Partners from './block_6_partners/Partners';
//import CustomerReviews from './block_7_customer_reviews/CustomerReviews';
import Footer from '../../modules/Footer/Footer';
import TransitionPages from '../../modules/Transition_Pages/TransitionPages';

function GranGotaMain() {
  return (
    <>
      <div className="gran_gota_main_conteiner">
        <Header />
        <PresentationSliderBlock />
        {/* <OursProjects /> */}
        <NumberOfFinisedWorks />
        <WhyChooseUs />
        <LandscapeDesign />
        {/* <Partners /> */}
        {/* <CustomerReviews /> */}
        <Footer />
      </div>
    </>
  );
}

export default TransitionPages(GranGotaMain);
