import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import './style_map_address_contacts.css';



function MapAddressContacts() {
  return (
    <div className="map_address_contacts_conteiner">
      <div className="map_address_contacts">
        <YMaps
          query={{
            apikey: '691c6606-e2a3-427e-92e0-a1dd7875b1b8',
            lang: 'ru_RU',
          }}
        >
          <div className="map_module_conteiner">
            <Map
              width={`100%`}
              height={`100%`}
              defaultState={{
                center: [51.674768, 39.166942],
                zoom: 17,
                controls: ['zoomControl', 'fullscreenControl'],
              }}
              modules={['control.ZoomControl', 'control.FullscreenControl']}
            >
              <Placemark
                modules={['geoObject.addon.balloon']}
                defaultGeometry={[51.674768, 39.166942]}
                properties={{
                  balloonContentBody: `Проспект Труда д.68 GRANGOTA`,
                }}
              />
            </Map>
          </div>
        </YMaps>
        <div className="address_contacts">
          <h1>Контакты</h1>
          <div className="address_numbers_mails_socials">
            <div className="contacts_address anmsh2 anmsc">
              <h2>Адрес</h2>
              <h3>г. Воронеж, пр. Труда д.68</h3>
            </div>
            <div className="contacts_phones anmsh2 anmsc">
              <h2>Номер телефона</h2>
              <a href="tel:+79092174447">+7 909 217-44-47</a>
            </div>
            <div className="contacts_mails anmsh2 anmsc">
              <h2>E-mail</h2>
              <a href="mailto:info@grangota.ru">info@grangota.ru</a>
            </div>
            {/* <div className="contacts_socials anmsc">
              <a href="google.com">LinkedIn</a>
              <a href="google.com">Instagram</a>
              <a href="google.com">Telegram</a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapAddressContacts;
