import './style_choose_pool_render.css';
import CompositePool from '../composite_pool_blocks/CompositePool';
import ConcretePool from '../concrete_pool_blocks/ConcretePool';

function ChoosePoolRender({ clickChoose }) {
  return (
    <div className="choose_pool_render_conteiner">
      <div className="choose_pool_render">
        <div className="choose_pool_text">
          <h1>
            превратите свой двор в <span>оазис удовольствия!</span>
          </h1>
          <p>Строим бассейны, которые преобразят ваш участок</p>
        </div>
        <div className="choose_pool_buttons">
          <a
            className="composite_pools choose_pool_button"
            href="#composite_pool_anchor"
            onClick={() => {
              clickChoose(<CompositePool />);
            }}
          >
            <p>
              Композитные
              <br /> бассейны
            </p>
          </a>
          <a
            className="concrete_pools choose_pool_button"
            href="#concrete_pool_anchor"
            onClick={() => {
              clickChoose(<ConcretePool />);
            }}
          >
            <p>
              Бетонные
              <br /> бассейны
            </p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ChoosePoolRender;
