import React from 'react';
import './style_our_finished_saunas.css';
import data_our_finished_saunas from './data_our_finished_saunas/data_our_finished_saunas';
import SlickSliderCenter from '../../../modules/Slick_Slider_Center/SlickSliderCenter';
//import ImageSlider from '../../../modules/Image_Slider/ImageSlider';

function OurFinishedSaunas() {
  return (
    <div className="our_finished_saunas_conteiner">
      <div className="our_finished_saunas">
        <h2>Наши работы</h2>
        <div className="our_finished_saunas_slider">
          <SlickSliderCenter data={data_our_finished_saunas} />
          {/* <ImageSlider images={data_our_finished_saunas} /> */}
        </div>
      </div>
    </div>
  );
}

export default OurFinishedSaunas;
