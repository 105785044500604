const east_hammam = [
  {
    id: 1,
    hammam_img: require('./img_east_hammam/310BEDB7-82C7-4A7C-8CE2-82B21C94C83B.png'),
  },

  {
    id: 2,
    hammam_img: require('./img_east_hammam/68BB558C-F625-41C3-A962-CC80AD837204.png'),
  },

  {
    id: 3,
    hammam_img: require('./img_east_hammam/E4109341-0CD8-4FA2-B1F8-9C0E85E64542.png'),
  },
];

export default east_hammam;
