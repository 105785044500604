import React from 'react';
import './style_advantage_concrete_pool.css';

function AdvantageConcretePool() {
  return (
    <div className="advantage_concrete_pool_conteiner">
      <div className="advantage_concrete_pool">
        <h1>
          <span>преимущества</span> выбора
          <br /> бетонных бассейнов
        </h1>
        <div className="advantage_concrete_pool_text_img">
          <div className="img_acpc_1 img_acpc advantage_concrete_pool_card"></div>
          <div className="text_acpc tcp1 advantage_concrete_pool_card">
            <h3>Долговечность</h3>
            <p>
              Бетонные бассейны обладают высокой степенью прочности и стойкости
              к износу, что обеспечивает их долговечность.
            </p>
          </div>
          <div className="text_acpc tcp2 advantage_concrete_pool_card">
            <h3>Доступность</h3>
            <p>
              Бетонные бассейны обычно дешевле других типов бассейнов, таких как
              композитные или стеклянные.
            </p>
          </div>
          <div className="img_acpc_2 img_acpc advantage_concrete_pool_card"></div>
          <div className="img_acpc_3 img_acpc advantage_concrete_pool_card"></div>
          <div className="text_acpc tcp3 advantage_concrete_pool_card">
            <h3>Гибкость дизайна</h3>
            <p>
              Бетон позволяет создавать бассейны различных форм и размеров, что
              дает большую свободу дизайна.
            </p>
          </div>
          <div className="text_acpc tcp4 advantage_concrete_pool_card">
            <h3>Стойкость к погоде</h3>
            <p>
              Бетон не подвержен коррозии или гниению, что делает его идеальным
              материалом для использования на открытом воздухе
            </p>
          </div>
          <div className="img_acpc_4 img_acpc advantage_concrete_pool_card"></div>
          <div className="img_acpc_5 img_acpc advantage_concrete_pool_card"></div>
          <div className="text_acpc tcp5 advantage_concrete_pool_card">
            <h3>Легкость обслуживания</h3>
            <p>
              Бетонные поверхности легко чистятся и не требуют особого ухода.
            </p>
          </div>
          <div className="text_acpc tcp6 advantage_concrete_pool_card">
            <h3>Экологичность</h3>
            <p>
              Бетон является экологически чистым материалом, так как он
              производится из натуральных ингредиентов и не содержит вредных
              веществ.
            </p>
          </div>
          <div className="img_acpc_6 img_acpc advantage_concrete_pool_card"></div>
        </div>
      </div>
    </div>
  );
}

export default AdvantageConcretePool;
