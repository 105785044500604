import React from 'react';
import './style_our_finished_hammam.css';
import data_our_finished_hammam from './data_our_finished_hammam/data_our_finished_hammam';
import SlickSliderCenter from '../../../modules/Slick_Slider_Center/SlickSliderCenter';

function OurFinishedHammam() {
  return (
    <div className="our_finished_hammams_conteiner">
      <div className="our_finished_hammams">
        <h2>Наши работы</h2>
        <div className="our_finished_hammams_slider">
          <SlickSliderCenter data={data_our_finished_hammam} />
        </div>
      </div>
    </div>
  );
}

export default OurFinishedHammam;
