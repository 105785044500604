import React from 'react';
import './style_contacts.css';
import Header from '../../modules/Header/Header';
import Footer from '../../modules/Footer/Footer';
import MapAddressContacts from './block_1_map_address_contacts/MapAddressContacts';
import TransitionPages from '../../modules/Transition_Pages/TransitionPages';
import OfficePhoto from './block_2_office_photo/OfficePhoto';

function Contacts() {
  return (
    <div className="contacts_conteiner">
      <Header />
      <MapAddressContacts />
      <OfficePhoto />
      <Footer />
    </div>
  );
}

export default TransitionPages(Contacts);
