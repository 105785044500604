//import React, { useState, useEffect } from 'react';
import './MainPageSlider.css'; // Подключаем стили для слайдера
import slide1 from '../../pages/gran_gota_main/block_1_presentation_slider_block/img/0ca5e97c92f73586efe38c84dc3ad45b.png';
import slide2 from '../../pages/gran_gota_main/block_1_presentation_slider_block/img/35ad5d0ca644f5c87ac44fe98904e426.png';
import slide3 from '../../pages/gran_gota_main/block_1_presentation_slider_block/img/7a8efbb6e35b8e78d64c5c6008281c77.png';
import slide4 from '../../pages/gran_gota_main/block_1_presentation_slider_block/img/82e84e0bc4c92e190a61fa1bb010d52a.png';
import PopUpForm from '../PopUpForm/PopUpForm';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
register();

const MainPageSlider = () => {
  // РАБОЧАЯ АНИМАЦИЯ!!!

  // const imgSpinning = [
  //   { transform: 'translateX(0)' },
  //   { transform: 'translateX(100%)' },
  // ];
  // const imgTiming = {
  //   duration: 2000,
  //   iterations: 1,
  // };

  // const img = document.querySelector('.presentation_slider_block');

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     //Рабочая анимация!!!
  //     img.animate(imgSpinning, imgTiming);
  //
  //   }, 5000); // Каждые 15 секунды
  //   return () => clearInterval(interval);
  // });

  return (
    <div className="presentation_slider_block">
      <div className="slider_swiper_conteiner">
        <Swiper
          autoplay={{ delay: 6000 }}
          spaceBetween={30}
          loop={true}
          effect={'fade'}
          modules={[EffectFade]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div
              className="sss1"
              style={{
                backgroundSize: `cover`,
                backgroundRepeat: `no-repeat`,
                backgroundPosition: `50%`,
                backgroundImage: `linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.3) 100%
      ),
      linear-gradient(180deg, rgba(17, 59, 71, 0) 48.01%, #113b47 100%), url('${slide1}')`,
              }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="sss1"
              style={{
                backgroundSize: `cover`,
                backgroundRepeat: `no-repeat`,
                backgroundPosition: `50%`,
                backgroundImage: `linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.3) 100%
      ),
      linear-gradient(180deg, rgba(17, 59, 71, 0) 48.01%, #113b47 100%), url('${slide2}')`,
              }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="sss1"
              style={{
                backgroundSize: `cover`,
                backgroundRepeat: `no-repeat`,
                backgroundPosition: `50%`,
                backgroundImage: `linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.3) 100%
      ),
      linear-gradient(180deg, rgba(17, 59, 71, 0) 48.01%, #113b47 100%), url('${slide3}')`,
              }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="sss1"
              style={{
                backgroundSize: `cover`,
                backgroundRepeat: `no-repeat`,
                backgroundPosition: `50%`,
                backgroundImage: `linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.3) 100%
      ),
      linear-gradient(180deg, rgba(17, 59, 71, 0) 48.01%, #113b47 100%), url('${slide4}')`,
              }}
            ></div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* <div className="previos_scroll_button"> */}
      {/* <p>ПРЕД</p> */}
      {/* </div> */}
      <div className="presentation_text_button">
        <h1>
          превратите свой двор в <span>оазис удовольствия!</span>
        </h1>
        <p>Строим бассейны, которые преобразят ваш участок</p>
        <PopUpForm
          classButton={'button_hover'}
          nameButton={'Получить консультацию'}
        />
        {/* <button>получить консультацию</button> */}
        {/* </div> */}
        {/* <div className="graph_circle">
        <div className="circle_animation"></div>
      </div>{' '} */}
        {/*СДЕЛАТЬ КРУГИ В ОДНОМ КОНТЕЙНЕРЕ, ЧТО БЫ ПОТОМ ЕГО ВРАЩАТЬ */}
        {/* <div className="pagination_number_for_circle"> */}{' '}
        {/*Нужно сделать прямоугольную полоску у активного скриншота */}
        {/* <p className="scroll_1 scroll_number">01</p>
        <p className="scroll_2 scroll_number">02</p>
        <p className="scroll_3 scroll_number">03</p>
        <p className="scroll_4 scroll_number">04</p> */}
        {/* </div> */}
        {/* <div className="next_scroll_button">СЛЕД</div> */}
      </div>
    </div>
  );
};

export default MainPageSlider;
