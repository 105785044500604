import React from 'react'
import Header from '../../modules/Header/Header'
import Footer from '../../modules/Footer/Footer'
import MsgNotFound from './block_1_msg_not_found/MsgNotFound'
import './style_not_found.css'

function NotFound() {
  return (
    <div className='not_found_conteiner'>
        <Header />
        <MsgNotFound />
        <Footer />
    </div>
  )
}

export default NotFound