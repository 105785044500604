import React, { useEffect, useState } from 'react';
import './style_temporary_component_slick_slider_pools.css';
import SlickSliderCenter from '../../../modules/Slick_Slider_Center/SlickSliderCenter';
import data_composite_pool from '../temporary_component_slick_slider_pools/data_composite_pool';
import data_concrete_pool from '../temporary_component_slick_slider_pools/data_concrete_pool';

function TemporaryComponentSlickSliderPools({ data_name }) {
  const [gen_data, setGenData] = useState(data_composite_pool);

  useEffect(() => {
    if (data_name === 'composite') {
      setGenData(data_composite_pool);
    } else if (data_name === 'concrete') {
      setGenData(data_concrete_pool);
    }
  }, [data_name]);

  return (
    <div className="temporary_conteiner_slider_pools">
      <h2>Наши работы</h2>
      <SlickSliderCenter data={gen_data} />
    </div>
  );
}

export default TemporaryComponentSlickSliderPools;
