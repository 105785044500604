//import { useState, useEffect } from 'react';
import './style_nav_menu.css';
import { AiOutlineMenu } from 'react-icons/ai';
import { AiOutlineClose } from 'react-icons/ai';
//import BurgerMenu from '../Burger_Menu/BurgerMenu';
import { motion } from 'framer-motion';

function NavMenu({ handleClick, nav }) {
  //ПОСМОТРЕТЬ КАК РЕАЛИЗОВАН STYLE НА САЙТЕ РЕФЕРЕНСЕ И СДЕЛАТЬ ТАК ЖЕ (widht:100wh height:100vh и т.д. )

  return (
    <motion.div animate={{ rotate: 360 }} className="nav_menu_conteiner">
      <div
        className="burger_icon"
        onClick={handleClick}
        // то что ниже вынести в отдельный компонент???
      >
        {nav ? <AiOutlineClose size="18rem" /> : <AiOutlineMenu size="18rem" />}
      </div>
      <div className="menu_info">
        <p>МЕНЮ</p>
      </div>
    </motion.div>
  );
}

export default NavMenu;
