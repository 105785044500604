import React from 'react';
import './style_landscape_design.css';

function LandscapeDesign() {
  return (
    <div className="landscape_design_conteiner">
      <div className="landscape_design">
        <div className="landscape_design_paragraph_text">
          <h2>преображение вашего участка</h2>
          <h3>
            Мы знаем, как сделать вашу территорию уникальной, красивой и удобной
            для жизни, работы и отдыха.
          </h3>
        </div>
        <div className="landscape_design_round_images">
          <div className="round_images_1 round_img"></div>
          <div className="round_images_2 round_img"></div>
          <div className="round_images_3 round_img"></div>
        </div>
        <div className="landscape_design_about_text">
          <div className="text_about_design">
            <p>
              Мы изучаем ваши пожелания, особенности территории и создаем
              уникальное предложение, которое учитывает все ваши требования и
              предпочтения.
            </p>
            <p>
              После утверждения проекта мы приступаем к его реализации. Мы
              осуществляем все работы по благоустройству территории, включая
              земляные работы, укладку покрытий, установку оборудования и
              посадку растений.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandscapeDesign;
