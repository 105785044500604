const wood_sauna = [
  {
    id: '1',
    sauna_img: require('./img_wood_sauna/b7696da47de8afd5b730196cb65cbd36.png'),
  },
  {
    id: '2',
    sauna_img: require('./img_wood_sauna/53370cc227587b1027953df55368918f.png'),
  },
  {
    id: '3',
    sauna_img: require('./img_wood_sauna/cfb604e666907e3000079ef2ab5b63e9.png'),
  },
];

export default wood_sauna;
