const modern_hammmam = [
  {
    id: 1,
    hammam_img: require('./img_modern_hammam/0020ca17719b1b3cc7476b5a58f872be.png'),
  },

  {
    id: 2,
    hammam_img: require('./img_modern_hammam/b57dbecf14a38bb1dfd4c0f5435c5640.png'),
  },

  {
    id: 3,
    hammam_img: require('./img_modern_hammam/d7fd7a3d4ee0fef1722a8b7d497b3b0e.png'),
  },
];

export default modern_hammmam;
