import React, { useState } from 'react';
import './style_pool_selection.css';
import Header from '../../modules/Header/Header';
import ChoosePoolRender from './block_1_choose_pool_render/ChoosePoolRender';
import TransitionPages from '../../modules/Transition_Pages/TransitionPages';

//import Footer from '../../modules/Footer/Footer';
// import AdvantageConcretePool from './concrete_pool_blocks/block_2_advantage_concrete_pool/AdvantageConcretePool';
// import PresentationConcretePool from './concrete_pool_blocks/block_1_presentation_concrete_pool/PresentationConcretePool';
// import AboutConcreteMaterial from './concrete_pool_blocks/block_3_about_concrete_material/AboutConcreteMaterial';
// import TechnicalSelectionConcretePool from './concrete_pool_blocks/block_4_technical_selection_concrete_pool/TechnicalSelectionConcretePool';
// import ColorSelectorConcretePool from './concrete_pool_blocks/block_6_color_selector_concrete_pool/ColorSelectorConcretePool';
// import ShapesConcretePools from './concrete_pool_blocks/block_5_shapes_concrete_pools/ShapesConcretePools';
// import PresentationCompositePool from './composite_pool_blocks/block_1_presentation_composite_pool/PresentationCompositePool';
// import AdvantageCompositePool from './composite_pool_blocks/block_2_advantage_composite_pool/AdvantageCompositePool';
// import AboutCompositeMaterial from './composite_pool_blocks/block_3_about_composite_material/AboutCompositeMaterial';
// import TechnicalSelectionCompositePool from './composite_pool_blocks/block_4_technical_selection_composite_pool/TechnicalSelectionCompositePool';
// import ColorSelectorCompositePool from './composite_pool_blocks/block_5_color_selector_composite_pool/ColorSelectorCompositePool';

function PoolSelection() {
  //const [composite_pool, setCompositePoole] = useState(false);
  //const [concrete_pool, setConcretePool] = useState(false);

  const [choose, setChoose] = useState(false);

  function clickChoose(param) {
    setChoose(param);
  }

  return (
    <div className="pool_selection_conteiner">
      <Header />
      <ChoosePoolRender clickChoose={clickChoose} />
      {choose}
    </div>
  );
}

export default TransitionPages(PoolSelection);

/* <PresentationConcretePool />
      {/*проверка на true/false
        <Общий родитель> 
          <Нужный блок с типом бассейна>
        </Общий родитель>
       * }
      <AdvantageConcretePool />
      <AboutConcreteMaterial />
      <TechnicalSelectionConcretePool />
      <ShapesConcretePools />
      <ColorSelectorConcretePool /> */
