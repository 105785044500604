import React from 'react';
//import Header from '../../../modules/Header/Header';
import './style_presentation_slider_block.css';
import MainPageSlider from '../../../modules/MainPageSlider/MainPageSlider';
//import active_circle from './img/Ellipse 21.png';

function PresentationSliderBlock() {
  return (
    <>
      <MainPageSlider></MainPageSlider>
    </>
  );
}

export default PresentationSliderBlock;
