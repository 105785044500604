import React from 'react';
import './style_about_company.css';
import Header from '../../modules/Header/Header';
import Footer from '../../modules/Footer/Footer';
import PresentationAboutCompany from './block_1_presentation_about_company/PresentationAboutCompany';
//import OurServices from './block_2_our_services/OurServices';
//import OursProjects from './block_3_ours_projects/OursProjects';
//import Partners from './block_4_partners/Partners';
import TransitionPages from '../../modules/Transition_Pages/TransitionPages';

function AboutCompany() {
  return (
    <div className="about_company_conteiner">
      <Header />
      <PresentationAboutCompany />
      {/* <OurServices /> */}
      {/* <OursProjects /> */}
      {/* <Partners /> */}
      <Footer />
    </div>
  );
}

export default TransitionPages(AboutCompany);
