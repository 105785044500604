import React from 'react';
import './style_presentation_sauna.css';

function PresentationSauna() {
  return (
    <div className="presentation_sauna_conteiner">
      {/* <div className="bread_crumbs">
        <p>Главная</p>
        <p>/</p>
        <p>Сауна</p>
      </div> */}
      <div className="presentation_sauna_text">
        <h1>Сауна</h1>
        <h2>от проекта до реализации</h2>
        <p>
          Здесь мы предлагаем вам ознакомиться с информацией о различных
          аспектах строительства сауны, а также подобрать оптимальные решения
          для вашего проекта.
        </p>
      </div>
      <div className="presentation_sauna_img">
        <div className="presentation_sauna_img_1 sauna_img"></div>
        <div className="presentation_sauna_img_2 sauna_img"></div>
        <div className="presentation_sauna_img_3 sauna_img"></div>
      </div>
    </div>
  );
}

export default PresentationSauna;
