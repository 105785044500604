import React from 'react'
import './style_msg_not_found.css'
import { Link } from 'react-router-dom'

function MsgNotFound() {
  return (
    <div className='msg_not_found_conteiner'>
        <h2>Такая страница не найдена</h2>
        <h3>Попробуйте начать <Link to={'/'}>здесь</Link></h3>
    </div>
  )
}

export default MsgNotFound