import React from 'react';
import './style_number_of_finished_works.css';

function NumberOfFinisedWorks() {
  return (
    <div className="number_of_finished_works_conteiner">
      <div className="number_of_finished_works">
        <div className="number_finished_works">
          <h3>успешно выполненных проектов по России</h3>
          <p>+80</p>
        </div>
        <div className="design_img_finished_works"></div>
        <div className="paragraph_finished_works">
          <div className="paragraph_finished_works_conteiner">
            <h3>
              Наши бассейны отличаются прочностью, долговечностью и
              экологичностью.
            </h3>
            <h2>проектирование, изготовление и монтаж</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NumberOfFinisedWorks;
