const electro_sauna = [
  {
    id: '1',
    sauna_img: require('./img_electro_sauna/2C297CB5-ABC8-4B3B-B1C4-22148BAFC334.JPG'),
  },
  {
    id: '2',
    sauna_img: require('./img_electro_sauna/03ADF92A-3145-4488-93B8-8E1FE15FCEDB.JPG'),
  },
  {
    id: '3',
    sauna_img: require('./img_electro_sauna/23F242C6-CD05-4921-83BE-2A77F04105B1.JPG'),
  },
];

export default electro_sauna;
