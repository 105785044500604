import React from 'react';
import PresentationCompositePool from './block_1_presentation_composite_pool/PresentationCompositePool';
import AdvantageCompositePool from './block_2_advantage_composite_pool/AdvantageCompositePool';
import AboutCompositeMaterial from './block_3_about_composite_material/AboutCompositeMaterial';
//import TechnicalSelectionCompositePool from './block_4_technical_selection_composite_pool/TechnicalSelectionCompositePool';
//import ColorSelectorCompositePool from './block_5_color_selector_composite_pool/ColorSelectorCompositePool';
import Footer from '../../../modules/Footer/Footer';
import TemporaryComponentSlickSliderPools from '../temporary_component_slick_slider_pools/TemporaryComponentSlickSliderPools';

function CompositePool() {
  return (
    <>
      <PresentationCompositePool />
      <AdvantageCompositePool />
      <AboutCompositeMaterial />
      {/* <TechnicalSelectionCompositePool /> --- нужен контент!!! */}
      {/* <ColorSelectorCompositePool --- нужен контент!!! /> */}
      <TemporaryComponentSlickSliderPools data_name={'composite'} />
      <Footer />
    </>
  );
}

export default CompositePool;
