import React from 'react';
import './style_design_stages_sauna.css';
import DesignStagesSlider from '../../../modules/Design_Stages_Slider/DesignStagesSlider';

function DesignStagesSauna() {
  return (
    <div className="design_stages_sauna_conteiner">
      <DesignStagesSlider object={'sauna'} />
    </div>
  );
}

export default DesignStagesSauna;
