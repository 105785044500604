import React from 'react';
import './style_sauna.css';
import PresentationSauna from './block_1_presentation_sauna/PresentationSauna';
import Header from '../../modules/Header/Header';
import ReasonForSauna from './block_2_reason_for_sauna/ReasonForSauna';
import DesignStagesSauna from './block_3_design_stages_sauna/DesignStagesSauna';
import TypesOfSaunas from './block_4_types_of_saunas/TypesOfSaunas';
import Footer from '../../modules/Footer/Footer';
import OurFinishedSaunas from './block_5_our_finished_saunas/OurFinishedSaunas';
import TransitionPages from '../../modules/Transition_Pages/TransitionPages';

function Sauna() {
  return (
    <div className="sauna_conteiner">
      <Header />
      <PresentationSauna />
      <ReasonForSauna />
      <DesignStagesSauna />
      <TypesOfSaunas />
      <OurFinishedSaunas />
      <Footer />
    </div>
  );
}

export default TransitionPages(Sauna);
