import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import FormFooter from '../../modules/Form_Footer/FormFooter';
import '../PopUpForm/style_popupform.css';

const PopUpForm = ({ nameButton, classButton }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const handleOverlayClick = (event) => {
    if (event.target.id === 'overlay') {
      closePopup();
    }
  };

  return (
    <>
      <button className={classButton} onClick={openPopup}>
        {nameButton}
      </button>

      {isOpen &&
        ReactDOM.createPortal(
          <div id="overlay" className="overlay" onClick={handleOverlayClick}>
            <div id="popup" className="popup">
              <FormFooter nameForm={'PopUpFormCopy'} />
              {/* <button onClick={closePopup}>Close</button> */}
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default PopUpForm;
