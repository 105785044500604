const data_sauna = [
  {
    id: '1',
    paragraph_design_stages: 'Определение целей и задач',
    text_design_stages:
      'На этом этапе необходимо определиться с тем, какую сауну вы хотите построить - большую, маленькую, с бассейном или без него, для семейного использования или для коммерческого.',
  },
  {
    id: '2',
    paragraph_design_stages: 'Выбор места',
    text_design_stages:
      'Важно выбрать подходящее место для сауны. Оно должно быть сухим, хорошо освещенным и проветриваемым. Также следует учесть близость к другим постройкам и коммуникациям.',
  },
  {
    id: '3',
    paragraph_design_stages: 'Создание проекта',
    text_design_stages:
      'На основе выбранных параметров создается проект сауны. Он включает в себя план помещения, чертежи стен, потолка, пола, а также схему электропроводки, водоснабжения и отопления.',
  },
  {
    id: '4',
    paragraph_design_stages: 'Выбор материалов',
    text_design_stages:
      'После создания проекта можно приступать к выбору материалов. Важно использовать только качественные и безопасные для здоровья материалы.',
  },
  {
    id: '5',
    paragraph_design_stages: 'Строительство',
    text_design_stages:
      'На данном этапе происходит непосредственно строительство сауны. Сначала устанавливаются фундамент и стены, затем монтируется потолок и пол, проводится электропроводка и система отопления, устанавливаются двери и окна.',
  },
  {
    id: '6',
    paragraph_design_stages: 'Отделка',
    text_design_stages:
      'Последним этапом является отделка сауны. Стены и потолок можно обшить вагонкой, на пол положить плитку или деревянные доски. Также необходимо установить мебель и аксессуары.',
  },
];

export default data_sauna;
